import './Footer.css';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';

export const Footer = () => {
  console.log('Breuk');
  return (
    <div className="footer">
      <div className="contact-info">
        <div className="contact-title">Contactanos</div>
        <div className="contact-data">
          <div className="data">
            <LocalPhoneOutlinedIcon /> (011) 52639743
          </div>
          <div className="data">
            <MailOutlineOutlinedIcon /> administracion@solucioneslogisticas-srl.com
          </div>
        </div>
      </div>
    </div>
  );
};
